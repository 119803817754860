/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './StyledApp';
import BaseTheme from './Theme';
import Home from './views/Home';
import Imprint from './views/Imprint';

function App() {
  return (
    <ThemeProvider theme={BaseTheme}>
      <GlobalStyle />
      <Router>
        <Switch>
          <Route path="/" exact render={(props) => <Home {...props} />} />
          <Route path="/imprint" exact render={(props) => <Imprint {...props} />} />
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
