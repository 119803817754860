import { createGlobalStyle } from 'styled-components';
import bodyBackground from './assets/bg.svg';
import InconsolataRegularWoff from './assets/fonts/inconsolata-v21-latin-regular.woff';
import InconsolataRegularWoff2 from './assets/fonts/inconsolata-v21-latin-regular.woff2';
import Raleway300Woff from './assets/fonts/raleway-v26-latin-300.woff';
import Raleway300Woff2 from './assets/fonts/raleway-v26-latin-300.woff2';
import RalewayRegularWoff from './assets/fonts/raleway-v26-latin-regular.woff';
import RalewayRegularWoff2 from './assets/fonts/raleway-v26-latin-regular.woff2';

const GlobalStyle = createGlobalStyle`

  /* raleway-300 - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 300;
    src: local(''),
        url(${Raleway300Woff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${Raleway300Woff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
  /* raleway-regular - latin */
  @font-face {
    font-family: 'Raleway';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url(${RalewayRegularWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url(${RalewayRegularWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  /* inconsolata-regular - latin */
  @font-face {
    font-family: 'Inconsolata';
    font-style: normal;
    font-weight: 400;
    src: local(''),
    url(${InconsolataRegularWoff2}) format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url(${InconsolataRegularWoff}) format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }

  *,
  *:before,
  *:after {
      box-sizing: border-box;
  }

  html {
    font-size: 1rem;
    height: 100vh;
    line-height: inherit;
    -ms-text-size-adjust: inherit;
    -webkit-text-size-adjust: inherit;

    background: url(${bodyBackground}) no-repeat left bottom fixed;
    background-size: cover;
    background-color: ${(props) => props.theme.colors.darkGrey};

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      background: url(${bodyBackground}) no-repeat center center fixed;
      background-size: cover;
      background-color: ${(props) => props.theme.colors.darkGrey};
    }
  }

  body {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    color: ${(props) => props.theme.colors.lightGrey2};
  }

  :focus,
  :active {
    outline: 0 !important;
  }

  .app {
    width: 100%;
    height: 100%;
  }

  ol, ul {
    margin: 0;
    padding: 0;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
`;

export default GlobalStyle;
