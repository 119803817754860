import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { textSizes } from '../utils/utils';
import Box from './Box';
import Text from './Text';

const StyledBullet = styled(Box)`
  /*
  &:hover {
    opacity: 0.75;
  }
  cursor: pointer;
  */
  & > * {
    cursor: default;
    font-family: Inconsolata, monospace;
    font-size: ${() => textSizes('xs')};
  }
  box-shadow: 1px 3px 6px 1px rgb(0 0 0 / 33%);

  display: flex;
  padding: 8px 12px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.backgroundColor ? props.theme.colors[props.backgroundColor] : 'transparent'};

  @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    padding: 10px 20px;
    & > * {
      font-size: ${() => textSizes('sm')};
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    padding: 12px 24px;
    & > * {
      font-size: ${() => textSizes('md')};
    }
  }
`;

function Bullet({ children, color, backgroundColor }) {
  return (
    <StyledBullet backgroundColor={backgroundColor} color={color}>
      <Text size="sm" bold color={color}>
        {children}
      </Text>
    </StyledBullet>
  );
}

Bullet.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string,
  backgroundColor: PropTypes.string,
};

Bullet.defaultProps = {
  children: null,
  color: '#fff',
  backgroundColor: 'purple',
};

export default Bullet;
