import React from 'react';
import styled from 'styled-components/macro';
import { ReactComponent as PhoneSvg } from '../assets/iphone_code.svg';

const StyledHolder = styled.div`
  @keyframes fadeInStayAndGoOut {
    0%,
    10%,
    20% {
      opacity: 0;
    }

    30%,
    40%,
    50%,
    60%,
    70% {
      opacity: 1;
    }

    80%,
    90%,
    100% {
      opacity: 0;
    }
  }

  @keyframes fadeOutStayAndGoIn {
    0%,
    10%,
    20% {
      opacity: 1;
    }

    30%,
    40%,
    50%,
    60%,
    70% {
      opacity: 0;
    }

    80%,
    90%,
    100% {
      opacity: 1;
    }
  }

  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
  align-items: flex-end;

  svg {
    width: 90px;

    @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
      width: 110px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      width: 120px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      width: 150px;
    }

    #presenting {
      opacity: 0;
      animation-name: fadeInStayAndGoOut;
      animation-duration: 12s;
      animation-timing-function: ease;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }

    #code {
      opacity: 1;
      animation-name: fadeOutStayAndGoIn;
      animation-duration: 12s;
      animation-timing-function: ease;
      animation-delay: 0s;
      animation-iteration-count: infinite;
      animation-direction: normal;
    }
  }
`;
function Phone() {
  return (
    <StyledHolder>
      <PhoneSvg />
    </StyledHolder>
  );
}

export default Phone;
