import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import Box from './Box';

const StyledContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;
  align-content: center;
  max-width: 80%;
  flex-wrap: wrap;

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    max-width: 600px;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    max-width: 720px;
  }
`;

function BulletContainer({ children, ...rest }) {
  return <StyledContainer {...rest}>{children}</StyledContainer>;
}

BulletContainer.propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  truncate: PropTypes.bool,
};

BulletContainer.defaultProps = {
  children: null,
  inline: false,
  truncate: false,
};

export default BulletContainer;
