import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components/macro';
import { paddingOrMarginToRem, textSizes } from '../utils/utils';

const StyledText = styled.span`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.lightGrey};
  font-size: ${(props) => (props.size ? textSizes(props.size) : textSizes('base'))};
  display: ${(props) => (props.inline ? 'inline-block' : 'block')};
  font-style: ${(props) => (props.italic ? 'italic' : 'normal')};
  text-align: ${(props) => (props.align ? props.align : 'left')};
  opacity: ${(props) => (props.opacity ? props.opacity : 1)};

  font-family: ${(props) =>
    props.font === 'mono' ? `Inconsolata, monospace;` : `'Raleway', sans-serif;`};

  font-weight: ${(props) => {
    if (props.bold) {
      return '700';
    }

    if (props.semibold) {
      return '500';
    }

    return 'inherit';
  }};

  ${(props) => props.whiteSpace && `white-space: ${props.whiteSpace}`};
  ${(props) => props.underline && `text-decoration: underline`};
  ${(props) => props.margin && `margin: ${paddingOrMarginToRem(props.margin)}`};
  ${(props) =>
    props.marginY && !props.marginX && `margin: ${paddingOrMarginToRem(props.marginY)} 0`};
  ${(props) =>
    props.marginX && !props.marginY && `margin: 0 ${paddingOrMarginToRem(props.marginX)}`};
  ${(props) =>
    props.marginX &&
    props.marginY &&
    `margin: ${paddingOrMarginToRem(props.marginY)} ${paddingOrMarginToRem(props.marginX)}`};
  ${(props) => props.marginTop && `margin-top: ${paddingOrMarginToRem(props.marginTop)}`};
  ${(props) => props.marginBottom && `margin-bottom: ${paddingOrMarginToRem(props.marginBottom)}`};
  ${(props) => props.marginLeft && `margin-left: ${paddingOrMarginToRem(props.marginLeft)}`};
  ${(props) => props.marginRight && `margin-right: ${paddingOrMarginToRem(props.marginRight)}`}
`;

function Text({ children, inline = false, truncate = false, ...rest }) {
  return (
    <StyledText
      {...(truncate && typeof children === 'string' ? { title: children } : null)}
      inline={inline}
      {...rest}
    >
      {children}
    </StyledText>
  );
}

Text.propTypes = {
  children: PropTypes.node,
  inline: PropTypes.bool,
  truncate: PropTypes.bool,
};

Text.defaultProps = {
  children: null,
  inline: false,
  truncate: false,
};

export default Text;
