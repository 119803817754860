const BaseTheme = {
  colors: {
    transparent: 'transparent',
    background: '#fafafa',
    white: '#fff',
    black: '#051014',

    green: '#00e4b7',
    blue: '#3498db',
    purple: '#9b59b6',
    grey: '#34495e',
    lightGrey: '#bdc3c7',
    darkGreen: '#16a085',
    darkBlue: '#2980b9',
    darkPurple: '#8e44ad',
    darkGrey: '#2c3e50',
  },
  breakpoints: {
    small: '480px',
    betweenSmall: '650px',
    medium: '960px',
    betweenMedium: '1025px',
    mediumLarge: '1280px',
    large: '1440px',
    xlarge: '2300px',
  },
};

export default BaseTheme;
