/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styled from 'styled-components/macro';
import Box from '../components/Box';
import Headline from '../components/Headline';
import Text from '../components/Text';

const StyledLink = styled.a`
  text-decoration: none;
  padding: 5px;

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.green};
    }
  }

  & > * {
    &:hover {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
`;

const StyledBackBtn = styled.a`
  display: block;
  background: ${(props) => props.theme.colors.darkGreen};
  border: 1px solid ${(props) => props.theme.colors.darkGreen};
  width: 120px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: all 0.5s ease;
  &:hover {
    background: ${(props) => props.theme.colors.green};
    border: 1px solid ${(props) => props.theme.colors.green};
  }
`;

const StyledImprint = styled(Box)``;

function Home() {
  return (
    <StyledContainer>
      <StyledImprint display="flex" justify="center" direction="column">
        <Headline>// Imprint</Headline>
        <Box marginTop={6} display="flex" justify="center" direction="column" alignItems="center">
          <Text color="white">
            Nico Schulze
            <br />
            Urbanstr. 2
            <br />
            70839 Gerlingen
            <br />
            Germany
            <br />
            <br />
            <StyledLink href="mailto: nico@nixosch.de">
              <Text color="white">nico@nixosch.de</Text>
            </StyledLink>
            <br />
            <br />
            Vat-ID: DE299290099
          </Text>

          <Box
            marginTop={10}
            display="flex"
            justify="center"
            direction="column"
            alignItems="center"
          >
            <StyledBackBtn href="/">Back to Page</StyledBackBtn>
          </Box>
        </Box>
      </StyledImprint>
    </StyledContainer>
  );
}

export default Home;
