/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';
import styled from 'styled-components/macro';
import Avatar from '../assets/me.jpg';
import Box from '../components/Box';
import Bullet from '../components/Bullet';
import BulletContainer from '../components/BulletContainer';
import Display from '../components/Display';
import Headline from '../components/Headline';
import Phone from '../components/Phone';
import Text from '../components/Text';

const StyledAvatar = styled.div`
  justify-content: center;
  align-items: center;
  align-content: center;
  display: flex;

  img {
    box-shadow: 11px 8px 10px 4px rgb(0 0 0 / 33%);
    width: 120px;
    border-radius: 20px 0 0 20px;

    @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
      width: 150px;
      border-radius: 20px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
      width: 200px;
      border-radius: 30px;
    }

    @media (min-width: ${(props) => props.theme.breakpoints.large}) {
      width: 220px;
      border-radius: 40px;
    }
  }
`;

const StyledAnimations = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1024px;

  @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
    width: 70%;
    padding-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 50%;
  }
`;

const StyledAnimationHolder = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1024px;

  @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
    width: 70%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 50%;
  }

  * {
    display: flex;
    justify-content: center;
  }
`;

const StyledSkillsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 1024px;

  @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
    width: 70%;
    padding-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 50%;
  }
`;

const StyledMeContent = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding-left: 20px;
  max-width: 1024px;

  @media (min-width: ${(props) => props.theme.breakpoints.betweenSmall}) {
    width: 70%;
    padding-left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.medium}) {
    width: 60%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.large}) {
    width: 50%;
  }
`;

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding-top: 80px;
  padding-bottom: 80px;
  gap: 50px;
`;

const StyledFooter = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

const StyledLink = styled.a`
  text-decoration: none;
  padding: 5px;

  &:hover {
    & > * {
      color: ${(props) => props.theme.colors.green};
    }
  }

  & > * {
    &:hover {
      color: ${(props) => props.theme.colors.green};
    }
  }
`;

const StyledFooterBorder = styled.div`
  width: 100px;
  border: 1px solid ${(props) => props.theme.colors.green};
  background: ${(props) => props.theme.colors.green};
  padding: 1px;
  border-radius: 18px;
  margin-bottom: 30px;
  margin-top: 80px;
`;

const StyledContactMe = styled.a`
  display: block;
  background: ${(props) => props.theme.colors.darkGreen};
  border: 1px solid ${(props) => props.theme.colors.darkGreen};
  width: 120px;
  text-align: center;
  text-decoration: none;
  color: #fff;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 10px;
  margin-top: 10px;
  transition: all 0.5s ease;
  &:hover {
    background: ${(props) => props.theme.colors.green};
    border: 1px solid ${(props) => props.theme.colors.green};
  }
`;

function Home() {
  return (
    <StyledContainer>
      <StyledMeContent>
        <Box marginRight={10}>
          <Headline>// Me</Headline>

          <Box>
            <Box opacity={0.9} background="darkGrey" paddingY={6} paddingX={4} marginY={4}>
              <Text color="white">
                Hi I&apos;m Nico, a freelancing Software Developer located in south Germany. Working
                as a Freelancer for 7 years.
              </Text>
            </Box>

            <Box display="flex" justify="center">
              <StyledContactMe href="mailto:nico@nixosch.de">Contact me !</StyledContactMe>
            </Box>
          </Box>
        </Box>

        <StyledAvatar>
          <img src={Avatar} alt="me" />
        </StyledAvatar>
      </StyledMeContent>

      <BulletContainer>
        <Bullet backgroundColor="darkGreen">#IT Freelancer 💻</Bullet>
        <Bullet backgroundColor="darkGreen">#7 Years ⏱️</Bullet>
        <Bullet backgroundColor="darkGreen">#Software Development 👨‍💻</Bullet>
        <Bullet backgroundColor="darkGreen">#12 Years ⏳</Bullet>
        <Bullet backgroundColor="darkGreen">#33 years old 🤖</Bullet>
        <Bullet backgroundColor="darkGreen">#Germany 🇩🇪</Bullet>
        <Bullet backgroundColor="darkGreen">#Runs on Coffee ☕</Bullet>
      </BulletContainer>

      <StyledAnimations>
        <Headline>// What I do </Headline>
        <Box opacity={0.9} background="darkGrey" paddingY={6} paddingX={4} marginY={4}>
          <Text color="white">
            Turn Ideas into something real! Got something wild in your mind?
            <br />
            Want to get rid of old processes and start over with something new?
            <br />
            <br />
            I&apos;m always interested in new Projects, coding ideas into Apps and Web-Apps that
            make life easier!
          </Text>
        </Box>
      </StyledAnimations>

      <StyledAnimationHolder>
        <Phone />
        <Display />
      </StyledAnimationHolder>

      <BulletContainer>
        <Bullet>#Web</Bullet>
        <Bullet>#Apps</Bullet>
        <Bullet>#iOS</Bullet>
        <Bullet>#Android</Bullet>
        <Bullet>#Architecture</Bullet>
        <Bullet>#Prototypes</Bullet>
        <Bullet>#Concepts</Bullet>
        <Bullet>#Support</Bullet>
        <Bullet>#Planing</Bullet>
        <Bullet>#Digitalisation</Bullet>
      </BulletContainer>

      <StyledSkillsContainer>
        <Headline>// How I do it</Headline>
        <Box opacity={0.9} background="darkGrey" paddingY={6} paddingX={4} marginY={4}>
          <Text color="white">
            Coming from a full stack development Background, I decided to focus more on Frontend
            technologies. JavaScript is my language of choice and still lets me build the complete
            package of all needs to a modern Application.
            <br />
            <br />
            Cloud Services and Software as a Service helps me speed up the process of building
            Software. But when things need to be extra individual, I am still capable of coding it
            myself.
          </Text>
        </Box>
      </StyledSkillsContainer>

      <BulletContainer>
        <Bullet backgroundColor="darkBlue">#Javascript</Bullet>
        <Bullet backgroundColor="darkBlue">#HTML</Bullet>
        <Bullet backgroundColor="darkBlue">#CSS</Bullet>
        <Bullet backgroundColor="darkBlue">#React</Bullet>
        <Bullet backgroundColor="darkBlue">#React Native</Bullet>
        <Bullet backgroundColor="darkBlue">#Typescript</Bullet>
        <Bullet backgroundColor="darkBlue">#NodeJS</Bullet>
        <Bullet backgroundColor="darkBlue">#Firebase</Bullet>
        <Bullet backgroundColor="darkBlue">#Databases</Bullet>
        <Bullet backgroundColor="darkBlue">#SaaS</Bullet>
      </BulletContainer>

      <StyledFooter>
        <StyledFooterBorder />

        <Box display="flex" direction="row" gap={20}>
          <StyledLink
            href="https://www.linkedin.com/in/nixoschu/"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text bold size="sm">
              LinkedIn
            </Text>
          </StyledLink>
          <StyledLink
            href="https://www.xing.com/profile/Nico_Schulze6"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text bold size="sm">
              Xing
            </Text>
          </StyledLink>
          <StyledLink
            href="https://stackoverflow.com/users/2630265/nixoschu"
            rel="noopener noreferrer"
            target="_blank"
          >
            <Text bold size="sm">
              StackOverflow
            </Text>
          </StyledLink>
          <StyledLink href="/imprint">
            <Text bold size="sm">
              Imprint
            </Text>
          </StyledLink>
        </Box>
        <Box marginTop={3}>
          <Text size="xs">&copy; Nico Schulze 2022</Text>
        </Box>
      </StyledFooter>
    </StyledContainer>
  );
}

export default Home;
